/* You can add global styles to this file, and also import other style files */
@import "remixicon/fonts/remixicon.css";
* {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
body {
  font-family: "Poppins", sans-serif;
  background:  #f2f2f2;
}
.container-oms{
  padding-top: 70px !important;
}
.oms-appwrapeer {
  width: 100%;
 // background-color: #f2f2f2;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.invoiceMenu {
  position: relative;
  top: -13px;
  left: -15px;
  width: 150px;
  button {
    color: #3a3a3a;
    border-bottom: 1px solid #cfd2e2;
    font-size: 10px;
    font-weight: 500;
    height: 30px;
    line-height: 30px;
    &:last-child {
      border: none;
    }
  }
}

// ****************************Gagan CSS**********************************
/* variables start */
$text-black-1: #3a3a3a;
$text-black-2: #000;
$text-grey-1: #a2a2a2;
$font-poppins: "Poppins", sans-serif;
$color-white: #fff !important;
/* variables end */

/* mixins start */
@mixin flex-center {
  display: flex;
  align-items: center;
}
@mixin heading($font-size, $line-height, $font-weight, $heading-color: $text-black-1) {
  font-size: $font-size !important;
  line-height: $line-height !important;
  font-weight: $font-weight !important;
  font-family: $font-poppins !important;
  color: $heading-color;
}
@mixin angletop($top, $left, $borderwidth: 10px, $anglecolor: #fff) {
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-right-width: $borderwidth;
  border-left-width: $borderwidth;
  border-bottom: $borderwidth solid $anglecolor;
  position: absolute;
  top: $top;
  left: $left;
}
/* mixins end */

/* common classes start */
.flex-center {
  @include flex-center;
}
.d-flex {
  display: flex;
}
.oms-wrapper {
  padding-right: 15px;
  padding-left: 15px;
  &.homeListingCards {
    padding: 30px;
  }
}
input::placeholder {
  font-family: $font-poppins;
}
.mr-20 {
  margin-right: 20px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.mx-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.p-0 {
  padding: 0px !important;
}
.m-0 {
  margin: 0px !important;
}
.px-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.px-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.p-10 {
  padding: 10px !important;
}
.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-5 {
  margin-left: 5px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.flex-column {
  flex-direction: column;
}
.dropdown-raduis {
  border-radius: 8px !important;
}
.w-50 {
  width: 50%;
}
.text-nowrap {
  white-space: nowrap;
}
.mt-20 {
  margin-top: 20px;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-2p {
  margin-right: 2%;
}
.ml-2p {
  margin-left: 2%;
}
.justify-between {
  justify-content: space-between;
}
.justify-right {
  justify-content: right !important;
}
.w-100 {
  width: 100% !important;
}
.success-txt {
  color: #299e22 !important;
}
.alert-txt {
  color: #f7653b !important;
}
/* background of the scrollbar except button or resizer */
.mordern-scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}
.mordern-scrollbar::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
// .mordern-scrollbar::-webkit-scrollbar-thumb {
//   background-color: #babac0;
//   border-radius: 16px;
//   border: 5px solid #fff;
// }
// .mordern-scrollbar::-webkit-scrollbar-thumb:hover {
//   background-color: #a0a0a5;
//   border: 4px solid #f4f4f4;
// }

/* set button(top and bottom of the scrollbar) */
// .mordern-scrollbar::-webkit-scrollbar-button {
//   display: none;
// }
.searchbar {
  display: inline-flex;
  width: 297px;
  border-radius: 8px;
  background: #f1f3f4;
  input {
    background: #f1f3f4;
    width: 84%;
    border: unset;
    padding: 0px 0px 0px 10px;
    border-radius: 10px 0px 0px 10px;
    &:focus {
      outline: unset;
    }
  }
  .input-group-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    .material-icons {
      color: #6f6f70;
    }
  }
}
.theme-input-box {
  border: 0.5px solid #cfd2e2;
  border-radius: 6px;
  padding: 10px 15px;
  legend {
    line-height: 100%;
    z-index: 2;
    font-size: 10px;
    line-height: 16px;
    position: relative;
  }
  mat-form-field {
    margin: -16px -15px -10px -15px;
    display: block;
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        padding: 0px 15px !important;
        background-color: transparent !important;
        display: flex;
        align-items: center;
        justify-content: center;
        .mat-form-field-infix {
          border-top: unset;
          input {
            font-size: 12px;
            line-height: 18px;
          }
          mat-select {
            font-size: 12px;
            line-height: 18px;
            .mat-select-arrow-wrapper {
              transform: translateY(0%) !important;
            }
          }
          .mat-form-field-label-wrapper {
            display: none;
          }
        }
      }
    }
  }
}
.required:after {
  content: " *";
  color: red;
}
/* common classes end */

/*common header start*/
.navbar {
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 0.6rem 0rem;
  position: fixed;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  z-index: 2;
  width: 100%;
  display: block;
  background-color: #fff;
  .oms-wrapper {
    @include flex-center;
    flex-wrap: inherit;
    justify-content: space-between;
    width: 100%;
  }
  .navbar-modal {
    cursor: pointer;
  }
  .navbar-brand {
    @include flex-center;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
  }
  .navbar-modal {
    display: flex;
    align-items: center;
  }
  .nav-item {
    @include flex-center;
  }
  .nav-item:not(:last-child) {
    margin-right: 10px;
  }
  .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    align-items: center;
    width: 100%;
  }
  .navbar-modal {
    margin-right: 0.8rem;
  }
  .navbar-nav {
    flex-direction: row;
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .me-auto {
    margin-right: auto !important;
    width: 100%;
    justify-content: flex-end;
  }
}
.dropdown-custom {
  background-color: #fff !important;
  border: 0.5px solid #cfd2e2 !important;
  border-radius: 4px !important;
  color: $text-black-2;

  img {
    margin-right: 5px;
  }
}
.material-icons {
  color: $text-black-2;
}
.heading-one {
  color: $text-black-1;
  @include heading(1rem, 2.125rem, 600);
}
.custom-grid-1 {
  mat-grid-tile {
    padding-top: unset !important;
    .mat-grid-tile-content {
      position: relative;
      top: unset;
    }
  }
}
.main-heading {
  @include heading(28px, 34px, 500);
  margin-bottom: 0px !important;
}
.main-sub-heading {
  @include heading(14px, 34px, 400);
}
/*common header end*/
/*search box with dropwdown start*/
.input-addon {
  height: 40px;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  width: 30%;
  display: inline-flex;
  background: #fff;
  input {
    border: unset;
    padding: 0px 0px 0px 10px;
    border-radius: 10px 0px 0px 10px;
  }
  .input-group-btn {
    height: 35px;
    position: relative;
  }
}
.input-group-btn::after {
  content: "";
  height: 20px;
  border-right: 1px solid #bfbfbf;
  position: absolute;
  left: 0px;
  top: 7px;
}
.clearfix {
  overflow: auto;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.mat-form-field .mat-form-field-wrapper {
  padding: 0px !important;
}

.input-group-btn {
  .mat-form-field {
    height: 100%;
    .mat-form-field-wrapper {
      height: 100% !important;
      .mat-form-field-flex {
        padding: 0em 0.75em 0 0.75em;
        border-radius: 4px 4px 4px !important;
        height: 100%;
        background-color: #fff;
        .mat-form-field-infix {
          padding: 0px;
          border-top: 1.1em solid transparent;
          width: 100px;
        }
      }
    }
    .mat-select-arrow {
      color: $text-black-1;
    }
  }
}
/*search box with dropwdown end*/
/*menu dropdown common start*/
.mat-form-field-flex {
  padding: 0em 0.75em 0 0.75em;
  border-radius: 4px 4px 4px !important;
  height: 100%;
  background-color: #fff !important;
  .mat-form-field-infix {
    padding: 0px;
    border-top: 1.1em solid transparent;
    width: 100px;
  }
}
.mat-form-field-underline {
  display: none;
}
// .mat-form-field {
//   height: 100%;
//   height: 35px;
//   .mat-form-field-wrapper {
//     height: 100% !important;
//   }
// }
.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0em 0.75em 0 0.75em !important;
}
.mat-form-field-type-mat-select .mat-form-field-label {
  color: $text-black-1;
}
.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: translateY(-30%) !important;
}
.mat-form-field.select-1 {
  border: 1px solid #bfbfbf;
  border-radius: 4px;
}
.mat-button-wrapper {
  @include flex-center;
}
/*menu dropdown common end*/
/* checkbox custom 1 start*/
.checkbox-1 {
  .mat-checkbox {
    @include flex-center;
    .mat-checkbox-inner-container {
      width: 14px;
      height: 14px;
    }
    .mat-checkbox-label {
      font-size: 12px;
      line-height: 17px;
      font-family: $font-poppins;
    }
  }
}
/* checkbox custom 1 end*/
/* checkbox start common style start*/

.mat-checkbox-checked .mat-ripple-element {
  background-color: #d9232d !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #d9232d !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #d9232d !important;
}
/* checkbox start common style start */
/* Menu dropdown with multiple checkboxs and search start*/
.multiple-checkbox-modal {
  max-width: 360px !important;
  min-width: 360px !important;
  @extend .dropdown-raduis;

  .modal-search {
    background-color: #f1f3f4;
    border: unset;
    margin: 0px 15px 15px 15px;
    padding: 8px 15px;
    width: 90%;
    @extend .dropdown-raduis;
  }
  &:before {
    content: "";
    @include angletop(-8px, 51px, 8px);
  }
}

.multiple-checkbox-section {
  flex-wrap: wrap;
  max-height: 360px;

  mat-checkbox {
    margin-bottom: 7px;
    .mat-checkbox-layout {
      .mat-checkbox-label {
        font-size: 13px;
        line-height: 20px;
      }
      .mat-checkbox-inner-container {
        height: 14px;
        width: 14px;
        margin-right: 10px;
      }
    }
    &:nth-child(n + 11) {
      margin-left: 30px;
    }
    &:last-child {
      margin-right: 15px;
    }
  }
}
.select-flags {
  padding: 7px 15px;
  border: 1px solid #ccc;
  text-align: left;
  background: #fff;
}

/* total width */
.mordern-scrollbar::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* checkbox dropdown start start */
.checkbox-dropdown {
  min-width: 120px !important;
  max-width: 120px !important;
  @extend .dropdown-raduis;
  &:before {
    content: "";
    @include angletop(-8px, 42px, 8px);
  }
  .mat-menu-content {
    padding: 0px !important;
  }
  mat-checkbox {
    padding: 6px 0px;

    .mat-checkbox-inner-container {
      height: 14px;
      width: 14px;
    }
    .mat-checkbox-label {
      line-height: 16px;
      font-size: 13px;
    }
  }
  mat-checkbox:not(:last-child) {
    border-bottom: 1px solid #cfd2e2;
  }
  .mat-checkbox-checked .mat-checkbox-label {
    color: #d9232d;
  }
}
/* checkbox dropdown start start */
/* hover tooltip start */
.hover-tooltip.tooptip-style-1 {
  cursor: pointer;
  position: relative;
  @include flex-center;
  .material-icons {
    font-size: 17px;
    color: #bfbfbf;
  }
  .tooltip-msg-box {
    display: none;
    position: absolute;
    left: -30px;
    top: 33px;
    border-radius: 8px;
    background: #f7f7f7;
    box-shadow: 0px 2px 8px #00000029;
    .tooltip-msg-left,
    .tooltip-msg-right {
      padding: 15px 20px 15px 15px;
    }
    .tooltip-msg-left {
      position: relative;
      &::after {
        content: "";
        height: 80%;
        top: 10%;
        border-right: 1px solid #cfd2e2;
        width: 2px;
        position: absolute;
        right: 0px;
      }
    }

    &:before {
      content: "";
      @include angletop(-8px, 31px, 8px, #f7f7f7);
    }
    h5 {
      font-family: $font-poppins;
      font-size: 10px;
      line-height: 16px;
      font-weight: 600;
    }
    ul {
      font-family: $font-poppins;
      font-size: 10px;
      line-height: 18px;
      li {
        margin-bottom: 4px;
      }
    }
  }
  &:hover .tooltip-msg-box {
    display: flex;
  }
}

/* hover tooltip end */

.checkbox-dropdown-2 {
  &:before {
    content: "";
    @include angletop(-8px, 22px, 8px);
  }
  .mat-menu-content {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  button {
    position: relative;
    height: 23px;
    line-height: 26px;
    border-radius: 4px;
    font-size: 12px;
    padding: 0 10px;
    font-weight: 400;
    overflow: visible;
    @include flex-center;
    font-family: $font-poppins;
    &:hover {
      background: #fce9ea !important;
    }
  }
  button:not(:last-child) {
    margin-bottom: 5px;
    &::after {
      content: "";
      height: 1px;
      position: absolute;
      bottom: -3px;
      width: 90%;
      left: 6px;
      border-bottom: 1px solid #cfd2e2;
    }
  }
}

/* history checkbox start */
/* modal content start */
.modal-heading {
  @include heading(14px, 24px, 700);
  span {
    @include heading(14px, 24px, 600);
  }
}
.modal-sub-heading {
  @include heading(12px, 24px, 700);
}
/* modal content start */
/* modal table start */
table {
  width: 100%;
}
.modal-table {
  table {
    font-family: $font-poppins;
    box-shadow: unset;
    tr {
      .mat-header-cell {
        background-color: #f5f5f5;
        border-bottom: unset;
        color: $text-black-1;
        font-size: 10px;
        line-height: 24px;
      }
      .mat-cell {
        background-color: #fff;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        @extend .mat-header-cell;
      }
    }
  }
}
/* modal table end */
/* button style tabs start */
.btn-tabs {
  mat-tab-header {
    border: unset;
    .mat-tab-label-active {
      background-color: $text-black-1;
      color: $color-white;
      border-radius: 4px;
      opacity: unset;
    }
    .mat-ink-bar {
      display: none;
    }
    .mat-ripple-element {
      display: none;
    }
  }
}
/* button style tabs end */
/* outline form start */
.outline-form {
  border: 0.5px solid #cfd2e2;
  border-radius: 6px;
  padding: 40px 30px;
  background-color: $color-white;
  h4 {
    @include heading(12px, 21px, 600);
    margin-bottom: 0px;
  }
}
.outline-form-inner {
  @extend .outline-form;
  padding: 9px 15px;
  h4 {
    @include heading(10px, 16px, 400);
    margin-bottom: 0px;
    color: $text-grey-1;
  }
}
/* outline form end */
/* custom popup 1 start */
.custom-popup-1 {
  max-width: 100vw !important;
  min-height: 70vh !important;
}
.custom-popup-1 mat-dialog-container {
  border-radius: 14px 14px 0px 0px !important;
}
// .cdk-overlay-container{
//   pointer-events: none !important;
// }
// .cdk-overlay-backdrop,.custom-popup-1, .cdk-global-overlay-wrapper {
//   background: rgb(51 51 51 / 90%);
//   pointer-events: none !important;
// }
.HistoryBackdropClass + .cdk-global-overlay-wrapper,
.customerInfoBackdropClass + .cdk-global-overlay-wrapper {
  align-items: flex-end !important;
}

.close-btn {
  min-width: unset !important;
  padding: 2px !important;
  border-radius: 50% !important;
  background-color: #000 !important;
}
.close-btn .material-icons {
  color: #fff;
  font-size: 16px !important;
}
/* custom popup 1 end */
/* Mega menu popup start*/
.megaMenuBackdropClass + .cdk-global-overlay-wrapper {
  align-items: flex-start !important;
  justify-content: flex-start !important;
}
.mega-menu {
  margin: 15px;
  max-width: 730px !important;
  mat-dialog-container {
    border-radius: 8px !important;
    .mat-dialog-content {
      max-height: 470px !important;
    }
  }
  mat-tab-header {
    padding: 25px 0px;
    background-color: #fafafa;
    width: 40% !important;
    border: unset;
    .mat-tab-labels {
      flex-direction: column;
      display: inline-flex !important;
      width: 100%;
      .mat-tab-label {
        min-width: 100% !important;
        justify-content: flex-start !important;
        opacity: 1;
        &:hover {
          background-color: #f1f3f4;
        }
        &.mat-tab-label-active .material-icons {
          opacity: 1 !important;
          transition: 0.3s ease all;
        }
        .mat-tab-label-content {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .material-icons {
            opacity: 0;
          }
          .megamenu-icons {
            @extend .mat-tab-label-content;
            justify-content: flex-start;

            img {
              padding: 2px;
              margin-right: 12px;
            }
          }
          .megamenu-icons-bg-1 img {
            background-color: #e6d9ff;
          }
          .megamenu-icons-bg-2 img {
            background-color: #ffddbc;
          }
          .megamenu-icons-bg-3 img {
            background-color: #ffb9ad;
          }
          .megamenu-icons-bg-4 img {
            background-color: #d1e6ff;
          }
          .megamenu-icons-bg-5 img {
            background-color: #dbffa2;
          }
          .megamenu-icons-bg-6 img {
            background-color: #ffd9ff;
          }
        }
      }
      .mat-tab-label.mat-tab-label-active {
        background-color: #f1f3f4;
      }
    }
    .mat-ink-bar {
      display: none;
    }
  }
  mat-tab-group {
    flex-direction: row !important;
    margin-top: 25px !important;
    height: 401px !important;
    .mat-tab-body-wrapper {
      width: 60%;
    }
  }
  .mega-menu-items {
    flex-wrap: wrap;
    max-height: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    .mat-button {
      margin-left: 15px;
      font-size: 1rem;
      line-height: 18px;
      font-weight: normal;
      padding: 7px 15px !important;
    }
  }
  .mat-dialog-actions {
    min-height: 34px;
    min-height: 34px;
    width: 34px;
    padding: 0px;
    margin-bottom: 0px;
    .close-btn-black {
      height: 34px;
      border-radius: 50%;
      width: 34px;
      padding: 0px;
      min-width: 24px;
      .mat-button-wrapper {
        justify-content: center;
      }
    }
  }
}
/* Mega menu popup end*/
/* address from start */
.address-info {
  width: 49%;
  .theme-input-box {
    width: 41%;
  }
}

/* address from end */
/* card style 1 start*/
.card-style-1 {
  width: 94%;
  border: 1px solid #cfd2e2;
  border-radius: 10px !important;
  box-shadow: unset !important;
  padding: 20px !important;
  mat-list {
    padding-top: 0px;
    mat-list-item {
      .mat-list-item-content {
        padding: 0px !important;
        .mat-button-wrapper {
          line-height: 27px;
          font-size: 11px;
          font-weight: 600;
          font-family: $font-poppins;
        }
      }

      .count-bagde {
        padding: 5px;

        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        width: 20px;
        font-family: $font-poppins;
        text-align: center;
        line-height: 100%;
      }
      .badge-color-green {
        background-color: #dff1de;
      }
      .badge-color-blue {
        background-color: #e4edfd;
      }
      .badge-color-pink {
        background-color: #fbe8e2;
      }
      .badge-color-light-pink {
        background-color: #f8dff5;
      }
      .list-text {
        margin-left: 15px;
        h3 {
          @include heading(14px, 18px, 600);
          margin-bottom: 2px;
        }
        h5 {
          @include heading(10px, 13px, normal);
          margin-bottom: 0px;
        }
      }
      &:not(:first-child) .mat-list-item-content {
        margin-top: 10px !important;
      }
      &:not(:last-child) .mat-list-item-content {
        padding-bottom: 10px !important;
      }
    }
  }
  mat-divider {
    left: 3% !important;
    width: 94% !important;
  }
}

.mogb_paginationrow {
  text-align: right;
  &>span {
      color: #909090;
      font-size: 10px;
      display: inline-block;
      margin-right: 2px;
      vertical-align: top;
      padding-top: 0px;
      border-right: 1px solid #909090;
      padding-right: 10px;
      font-weight: 500;
      margin-top: 19px;
  }
  .mogb_pagination {
      display: inline-block;
      vertical-align: top;
      padding: 17px 0 10px;
  }
}

.mogb_pagination {
  list-style: none;
  padding: 10px 0;
  margin: 0;
  text-align: right;
  position: relative;
  li {
      display: inline-block;
      margin: 0 2px;
      vertical-align: top;
      span {
          min-width: 17px;
          height: 17px;
          border-radius: 4px;
          display: block;
          font-size: 10px;
          color: gray;
          line-height: 17px;
          text-align: center;
          font-weight: 500;
          text-transform: uppercase;
      }
      &.active {
          cursor: pointer;
          span {
              background: gray;
              color: #fff;
              text-align: center;
          }
      }
      // @10766
      &.non-active {
          cursor: pointer;
          span {
              // color: $blue-color;
              background: none;
          }
      }
      &:last-child {
          &.active {
              span {
                  color: blue;
                  background: none;
                  cursor: pointer;
              }
          }
          &.non-active {
              span {
                  // color: $blue-color;
                  background: none;
              }
          }
      }
  }
}
/* card style 1 end*/
::-webkit-scrollbar-thumb {

 background: #d9d9d9;

  cursor: pointer;
}
::-webkit-scrollbar {
  width: .5em;
  height: 0.5em;
}

.wid100{
  width: 100% !important;
}
// <<<<<<< HEAD

// .primary{
//   background-color: #D9232D;
//   color: #fff;
//   font-size: 14px;
//   font-weight: bold;
// }

// .secondary{
//   background-color: #3A3A3A;
//   color: #fff;
//   font-size: 14px;
//   font-weight: bold;  
// =======
.ri-close-circle-fill{
  cursor: pointer;
}
button{
  border: none;
  outline: none !important;

}